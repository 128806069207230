<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <!-- Plot Details Section -->
      <h5>Plot Details</h5>
      <b-card class="user-details border-0 mb-3">
        <b-card-text>
          <table class="table table-sm ">
            <tr>
              <th>Project Name</th>
              <td>{{ plotDetails._project_id.name }}</td>
            </tr>
            <tr>
              <th>Zone Name</th>
              <td>{{ plotDetails._zone_id.code }}</td>
            </tr>
            <tr>
              <th>Plot Name</th>
              <td>{{ plotDetails._plot_id.code }}</td>
            </tr>
            <tr>
              <th>Registration Date</th>
              <td>{{ plotDetails.created_ts | moment("ddd,Do MMM  YYYY") }}</td>
            </tr>
          </table>
        </b-card-text>
      </b-card>

      <b-row no-gutters class="mb-3">
     
      </b-row>

      <b-table
        id="my-table"
        class="dataTable rounded-left"
        :outlined="true"
        :items="items"
        :fields="fields"
        head-variant="none"
        table-variant="none"
        :caption-top="true"
        show-empty
      >
        <template #empty="scope">
          <h4>{{ scope.emptyText }}</h4>
        </template>
        <template #table-caption>Plantation Assets</template>
        <template #cell(#)="data">{{ data.index + 1 }}</template>
        <template #cell(image)="row">
          <img
            :src="row.item._asset_id.image"
            alt="asset image"
            height="100px"
            width="100px"
            style="border-radius: 10px"
          />
        </template>
        <template #cell(status)="row">
          <span v-if="row.item.status == 1">
            completed
          </span>
          <span v-if="row.item.status == 0">
            pending
          </span>
        </template>
        <template #cell(plantation_date)="row">
          <span v-if="row.item.status == 1">
            {{ row.item.plantation_date | moment("ddd,Do MMM  YYYY") }}
          </span>
          <span v-if="row.item.status == 0">
            {{ row.item.planned_plantation_date | moment("ddd,Do MMM  YYYY") }}
          </span>
        </template>
       
      </b-table>

      <b-table
        id="my-table"
        class="dataTable rounded-left"
        :outlined="true"
        :items="plotDetails._plot_id.activities"
        :fields="plotFields"
        head-variant="none"
        table-variant="none"
        :caption-top="true"
        show-empty
      >
        <template #empty="scope">
          <h4>{{ scope.emptyText }}</h4>
        </template>
        <template #table-caption>Plot Activities</template>
        <template #cell(#)="data">{{ data.index + 1 }}</template>
        <template #cell(startDate)="row">
          {{ row.item._activiesId.start_date | moment("ddd,Do MMM  YYYY") }}
        </template>
        <template #cell(endDate)="row">
          {{ row.item._activiesId.end_date | moment("ddd,Do MMM  YYYY") }}
        </template>
        <template #cell(status)="row">
          <span v-if="row.item.status == 1">
            Pending
          </span>
          <span v-if="row.item.status == 2">
            In Progress
          </span>
          <span v-if="row.item.status == 3">
            Completed
          </span>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      plotDetails: "",
      plotPlantationDetails: "",
      fields: [
        "#",
        {
          key: "_asset_id.name",
          label: "Asset Name",
        },
        {
          key: "image",
          label: "Asset Image",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "plantation_date",
          label: "Plantation Date / Planned Plantation Date",
        },
        {
          key: "quantity",
          label: "Qty",
        }
       
      ],
      items: [],
      plotFields: [
        "#",
        {
          key: "_activiesId._activityTypeID.name",
          label: "Activity Type",
        },
        {
          key: "_activiesId.description",
          label: "Activity Description",
        },
        {
          key: "startDate",
          label: "Start Date",
        },
        {
          key: "endDate",
          label: "End Date",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "progress",
          label: "Progress(%)",
        },
      ],
    };
  },
  mounted() {
    this.getCustomerPlotDetails();
  },
  methods: {
    deletePlotPlantationAssets(id) {
      let plot_id = this.plotDetails._plot_id._id;
      this.getPlotDetails();
      let prompt = confirm("Are you sure want to delete ? ");
      if (prompt) {
        window.axios
          .delete("/asset-plot-alignment", {
            params: {
              plot_id,
              plantationAssetsId: id,
            },
          })
          .then((resp) => {
            if (resp.data.success) {
              this.getPlotDetails();
            }
          });
      }
    },

    getCustomerPlotDetails() {
      let id = this.$route.params.plot_id;
      window.axios.get("/customer-plots/details/" + id).then((resp) => {
        this.plotDetails = resp.data.data;
        this.getPlotDetails();
      });
    },
    getPlotDetails() {
      let plot_id = this.plotDetails._plot_id._id;
      console.log(plot_id);
      window.axios
        .get("/plot-asset", {
          params: {
            plot_id: plot_id,
          },
        })
        .then((resp) => {
          console.log(resp);
          this.plotPlantationDetails = resp.data.data;
          this.items = this.plotPlantationDetails.plantationAssets;
          console.log("Items");
          console.log(this.items);
        });
    },
  },
};
</script>

<style scoped>
.user-details .card {
  border-radius: 11px;
}

.user-details .card-body {
  background-color: #dcffd9;
  border-radius: 11px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.table > tr:first-child > td,
.table > tr:first-child > th {
  border: none;
}

/deep/ .dataTable {
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable thead {
  background-color: #e6e6e6;
  height: 51px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: "Poppins", sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
/deep/ .dataTable tr td {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}

/deep/ .dataTable caption {
  border-radius: 10px 10px 0 0;
  height: 64px;
  width: 100%;
  background-color: #dcffd9;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  padding-left: 27px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 25px;
}

.fa-trash-alt {
  height: 18px;
  width: 30px;
  color: red;
}

.fa-trash-alt:hover {
  cursor: pointer;
}
</style>
